import React from "react";
import { Container } from "./styled";

const Version = () => {
  return (
    <Container>
      <h1>showrun Frontend</h1>
      <div className="content">
        <h3>Version : 0.4.7 </h3>
        <h3>Build Version : 0.4.7.2 </h3>
        <h3>Dev Version : 0.8.4 </h3>
        <h5>Dev Release Date : 2024/10/12 </h5>
      </div>
      {/* Note
        [MAJOR.MINOR.REVISION.BUILDNUMBER] */}
    </Container>
  );
};

export default Version;
