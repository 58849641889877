import React, { useState } from "react";

import UIButton from "../../../../../../../common/components/Buttons/UIButton";
import InputField from "../../../../../../../common/components/Form/InputField";
import TextAreaField from "../../../../../../../common/components/Form/TextAreaField";
import FieldGroup from "../../../../../../../common/components/Form/FieldGroup";
import FileUploadManager from "../../../../../../../common/components/Form/FileUploadManager";
import FormGroup from "../../../../../../../components/controls/FromGroup";
import InsertUrlList from "../../../../../../../common/components/Form/InsertUrlList/index";
import DocumentIcon from "../../../../../../../common/components/icons/Line/Details/01";
import TimeLine from "./Timeline";
import {
  popupContainer,
  popupHeader,
  popupFooter,
  buttonLeft,
  buttonRight,
  labelStyle,
  dueDateBadge,
  dueDateContainer,
  attachmentSection,
  separatorLine1,
  separatorLine2,
  verticalSeparator,
  formSection,
  h3style,
  h4style,
  dueDateText,
  dueDateBold,
  iconStyle,
  scrollStyle,
} from "./style";
import ChangeRequestToast from "./Toast";
import { useMilestone } from "../../../context/MilestoneContext";

const sectionList = [{ value: "1", label: "Files" }];

const Form = () => {
  const { toggleDeliverableModalHandler } = useMilestone();

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [changeRequestDescription, setChangeRequestDescription] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [uploadFiles, setUploadedFiles] = React.useState([
    // {
    //   filePath:
    //     "https://saruwataarchive.blob.core.windows.net/loop-documents-dev/be85c302-18a6-4b34-9722-639e9942b7c0-2024-10-11-08-33-51-images.jpeg",
    //   fileName: "images.jpeg",
    //   localId: "b49c0e42-e5fa-40a3-9c80-d4682244521c",
    //   extension: "jpeg",
    //   sectionValue: "1",
    //   isSorted: true,
    // },
  ]);

  const handleAcceptChangeRequest = () => {
    console.log("Change Request Accepted.");
  };

  const handleRejectChangeRequest = () => {
    console.log("Change Request Rejected..");
  };

  const submitHandle = () => {
    const values = {
      subject,
      description,
      changeRequestDescription,
      uploadFiles,
      urlList,
    };

    console.log("Submit", values);
  };

  return (
    <div style={popupContainer}>
      <div style={{ display: "flex" }}>
        <div style={iconStyle}>
          <DocumentIcon width={"40px"} height={"45px"} />
        </div>

        <div style={popupHeader}>
          <h4 style={h4style}>Deliverables Submission</h4>
          <h3 style={h3style}>Staff Contracts & Log</h3>
        </div>
      </div>

      <hr style={separatorLine1} />

      <div style={{ display: "flex" }}>
        {/* left col (Due Date stuff..) */}
        <div style={scrollStyle}>
          <div style={dueDateContainer}>
            <div style={dueDateBadge}>
              <div style={dueDateText}>Due: Today</div>
              <div style={dueDateBold}>22 Sep 2024</div>
            </div>

            <TimeLine
              Status={"Prepared"}
              AuthorName={"Selvia Lamke"}
              TimelineDate={"24/08/24"}
              TimelineTime={"08:16"}
            />
            <TimeLine
              Status={"Change"}
              AuthorName={"Dharshaka Lanka"}
              TimelineDate={"24/08/24"}
              TimelineTime={"08:17"}
            />
            <TimeLine
              Status={"Resolved"}
              AuthorName={"Selvia Lamke"}
              TimelineDate={"24/08/24"}
              TimelineTime={"08:23"}
            />
            <TimeLine
              Status={"Approved"}
              AuthorName={"Supun Perera"}
              TimelineDate={"24/08/24"}
              TimelineTime={"08:45"}
            />
          </div>
        </div>

        <div style={verticalSeparator}></div>

        {/* right col (Form field stuffs..) */}
        <div style={formSection}>
          <ChangeRequestToast
            RequestTitle={"Change Request 001"}
            RequestAuthor={"Andrew Simon"}
            RequestedTime={"9 min ago"}
            RequestMessage={
              "It took the Hostage Support Partners, a pro-bono group seeking to free forgotten hostages in Somalia, 18 months to negotiate."
            }
            onAccept={handleAcceptChangeRequest}
            onReject={handleRejectChangeRequest}
          />

          <div>
            <FieldGroup>
              <label style={labelStyle}>Subject</label>
              <InputField
                placeholder="Enter Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                style={{ justifyContent: "flex-start" }}
              />
            </FieldGroup>
          </div>

          <div>
            <FieldGroup>
              <label style={labelStyle}>Description</label>
              <TextAreaField
                placeholder="Enter description here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FieldGroup>
          </div>

          <div>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <FieldGroup>
                  <label style={labelStyle}>Link</label>
                  <InsertUrlList
                    isPreviewMode={false}
                    value={urlList}
                    onChange={(values) => {
                      setUrlList(values);
                    }}
                  />
                </FieldGroup>
              </div>
            </div>
          </div>

          <div style={attachmentSection}>
            <div>
              <FormGroup>
                <label style={labelStyle}>Attachments</label>
                <FileUploadManager
                  //   generalPlaceholderText="Video/Audio/Images"
                  isPreviewMode={false}
                  defaultValue={uploadFiles}
                  uploadSections={sectionList}
                  onChange={(payload) => {
                    setUploadedFiles(payload?.value);
                    // console.log(payload?.value);
                  }}
                />
              </FormGroup>
            </div>
          </div>

          <div>
            <FieldGroup>
              <label style={labelStyle}>Change Request</label>
              <TextAreaField
                placeholder="Enter reason here"
                value={changeRequestDescription}
                onChange={(e) => setChangeRequestDescription(e.target.value)}
              />
            </FieldGroup>
          </div>
        </div>
      </div>

      <hr style={separatorLine2} />

      <div style={popupFooter}>
        <div style={buttonLeft}>
          <UIButton
            size={"sm"}
            variant={UIButton.Variant.Danger}
            onClick={() => toggleDeliverableModalHandler(false)}
          >
            Cancel
          </UIButton>
        </div>
        <div style={buttonRight}>
          <UIButton
            variant={UIButton.Variant.Secondary}
            size={"sm"}
            onClick={() => console.log("Submit")}
          >
            Change request
          </UIButton>
          <UIButton
            variant={UIButton.Variant.Success}
            size={"sm"}
            onClick={submitHandle}
          >
            Submit
          </UIButton>
        </div>
      </div>
    </div>
  );
};

export default Form;
