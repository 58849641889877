import React from "react";

const useFiles = ({
  onFileUpload,
  allowTypes = ["image/png", "image/jpeg", "image/tiff", "image/x-tiff", "application/tiff"],
  maxFileSize = 2,
  isUploading = false,
}) => {
  const [isDrop, setIsDrop] = React.useState(false);
  const [isDragStart, setIsDragStart] = React.useState(false);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [error, setError] = React.useState("");
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const maxAllowedSize = Math.abs(maxFileSize) * 1024 * 1024;

  const handleFiles = React.useCallback(
    async (files, onError, onDone) => {
      setError("");
      setUploadedFiles([]);

      const fileList = Array.from(files);

      console.log("File Type is: ", fileList.map(file => file.type));

      // const matchFilesWithAllowType = fileList.filter(
      //   (file) => allowTypes.indexOf(file.type) > -1
      // );

      const matchFilesWithAllowType = fileList.filter((file) => {
        // Allow tiff files even if their MIME type is something unexpected
        return allowTypes.indexOf(file.type) > -1 || file.name.endsWith(".tiff") || file.name.endsWith(".tif");
      });

      // Check if the file type is allowed
      if (matchFilesWithAllowType.length !== fileList.length) {
        let types = allowTypes.map((type) => type.split("/")[1]).join(", ");
        setError(`Invalid file type. Allowed file types are: ${types}`);
        onError && onError("FILE_NOT_ALLOWED");
        return;
      }

      // Check if the file size is exceed
      const matchFilesSizeWithMaxAllowSize = fileList.filter(
        (file) => file.size <= maxAllowedSize
      );

      if (matchFilesSizeWithMaxAllowSize.length !== fileList.length) {
        setError(
          `The file is too large. Allowed maximum size is: ${maxFileSize}MB`
        );
        onError && onError("MAX_FILE_SIZE");
        return;
      }

      let __files = Array.from(files).map((file) => {
        // Define a new file reader
        let reader = new FileReader();
        let base64Text = ";base64,";

        // Create a new promise
        return new Promise((resolve) => {
          // Resolve the promise after reading file
          reader.onload = () => {
            resolve({
              file: file,
              fileUrl: reader.result,
              fileData: reader.result.substring(
                reader.result.indexOf(base64Text) + base64Text.length
              ),
            });
          };

          // Read the file as a image
          reader.readAsDataURL(file);
        });
      });

      let res = await Promise.all(__files);

      setUploadedFiles(res);
      onDone && onDone();
    },
    [allowTypes, maxAllowedSize, maxFileSize]
  );

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter") {
      setIsDragStart(true);
    } else if (e.type === "dragover") {
      setIsDragOver(true);
    } else if (e.type === "dragleave") {
      setIsDragStart(false);
      setIsDragOver(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setIsDragStart(false);
    setIsDragOver(false);
    setIsDrop(true);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files, null, () => {
        e.dataTransfer.clearData();
      });
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(
        e.target.files,
        () => {
          e.target.value = "";
        },
        () => {
          e.target.value = "";
        }
      );
    }
  };

  React.useEffect(() => {
    if (uploadedFiles.length > 0) {
      onFileUpload && onFileUpload(uploadedFiles);
      setUploadedFiles([]);
    }
  }, [onFileUpload, uploadedFiles]);

  return {
    error,
    uploadedFiles,
    isDragStart,
    isDrop,
    isDragOver,
    // functions
    handleDrag: isUploading ? null : handleDrag,
    handleDrop: isUploading ? null : handleDrop,
    handleChange: isUploading ? null : handleChange,
  };
};

export default useFiles;
