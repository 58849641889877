export const SubmissionStatusTypes = {
  None: 0,
  Prepared: 1,
  Updated: 2,
  Submitted: 3,
  ChangeRequested: 4,
  Resubmitted: 5,
  Rejected: 6,
  Approved: 7,
};

export const CellStatusTypes = {
  APPROVED: SubmissionStatusTypes.Approved,
  PENDING: SubmissionStatusTypes.Prepared,
  RESUBMIT: SubmissionStatusTypes.Resubmitted,
};

export const CellTypes = {
  DATE_TYPE: 0,
  STATUS_TYPE: 1,
};

export const Units = [
  { label: "None", value: 0 },
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Months", value: 3 },
];

export const Operators = [
  { label: "None", value: 0 },
  { label: "On", value: 1 },
  { label: "Before", value: 2 },
  { label: "After", value: 3 },
];
