import SCHEDULE_API from "../../../services/api-scheduler";
import { MILESTONE_ENDPOINTS } from "./milestone.endpoints";

export const getMilestoneTracker = async (projectId) => {
  const data = {
    project: null,
    headers: [],
    sections: [],
    eventlist: [],
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await SCHEDULE_API.get(
        `${MILESTONE_ENDPOINTS.GET_MILESTONE_TRACKER}/${projectId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const milestoneTracker = response?.data?.result;

      data.project = milestoneTracker?.projectDetails || null;

      data.headers = milestoneTracker?.tableHeaders?.map((header) => {
        return {
          label: header?.label,
          key: header?.columnId,
          type: header?.type,
        };
      });

      data.sections = mapData(milestoneTracker?.sections || []);
      data.eventlist = milestoneTracker?.eventOptions || [];
      data.eventlist = milestoneTracker?.eventOptions?.map((option) => {
        return {
          label: option?.label,
          value: option?.eventId,
        };
      });

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveRow = async (_projectId, _parentId, data) => {
  let params = {
    deliverableId: data?.deliverableId || 0,
    projectId: _projectId,
    label: data?.label || "",
    eventId: data?.eventId || 0,
    parentId: _parentId,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SAVE_ROW}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }
      console.log("MILESTONE_ENDPOINTS.SAVE_ROW:: ", res);

      const data = mapData([res?.data?.result]);
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const saveCondition = async (_projectId, data) => {
  let params = {
    conditionId: data?.value?.value?.conditionId || 0,
    value: data?.formValues?.value || 0,
    unit: data?.formValues?.unit || 0,
    operator: data?.formValues?.operator || 0,
    eventId: data?.row?.original?.eventId || 0,
    cellId: data?.value?.cellId || 0,
  };

  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SAVE_CONDITION}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const saveSubmission = async (_projectId, data) => {
  let params = {
    submissionId: 0,
    subject: "string",
    note: "string",
    changeRequest: "string",
    state: 0,
    cellId: 0,
    links: [
      {
        resourceId: 0,
        resourceType: 1,
        url: "string",
        label: "string",
        extension: "string",
        md5sum: "string",
      },
    ],
    attachments: [
      {
        resourceId: 0,
        resourceType: 1,
        url: "string",
        label: "string",
        extension: "string",
        md5sum: "string",
      },
    ],
  };
  return new Promise(async (resolve, reject) => {
    try {
      let res = await SCHEDULE_API.post(
        `${MILESTONE_ENDPOINTS.SAVE_SUBMISSION}`,
        params
      );

      if (!res?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = res?.data?.result;
      resolve(data);
    } catch (err) {
      if (err.response && err.response.data) {
        const error = err.response.data;
        reject({
          systemError: {
            errors: error.errors,
            title: error.title,
          },
        });
      } else {
        reject(err);
      }
    }
  });
};
export const getSubmissionData = async (submissionId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await SCHEDULE_API.get(
        `${MILESTONE_ENDPOINTS.GET_SUBMISSION_DATA}/${submissionId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const data = response?.data?.result;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
function mapData(arr = []) {
  function data(d) {
    return {
      ...d,
      subSections: [],
      subRows: d?.subSections.map((dd) => data(dd)),
    };
  }
  return arr.map((d, i) => {
    return data(d);
  });
}
