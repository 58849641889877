import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../../common/components/Form/InputField";
import SelectField from "../../../../../../../common/components/Form/SelectField";
import { Operators, Units } from "../../../constant";
import { useMilestone } from "../../../context/MilestoneContext";

const initialValues = {
  value: "",
  unit: null,
  operator: null,
  eventId: 0,
};

const validationSchema = Yup.object().shape({
  value: Yup.number()
    .typeError("Enter numbers only")
    .required("Value field is required"),
  unit: Yup.string().required("Unit field is required").nullable(),
  operator: Yup.string().required("Operator field is required").nullable(),
  eventId: Yup.number().required("event field is required"),
});

const Form = ({ body, onClose, onRootClose, visibility, onSubmit }) => {
  const { eventsList = [], selectedDueCellValues } = useMilestone();
  console.log("selectedDueCellValues", selectedDueCellValues);

  const eventId = selectedDueCellValues?.row?.original?.eventId || 0;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (value, options) => {
      onSubmit && onSubmit({ ...value }, options);
    },
  });

  const {
    touched,
    errors,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  React.useEffect(() => {
    setFieldValue("value", selectedDueCellValues?.value?.value?.value || 0);
    setFieldValue("unit", selectedDueCellValues?.value?.value?.unit || 0);
    setFieldValue(
      "operator",
      selectedDueCellValues?.value?.value?.operator || 0
    );
    setFieldValue("eventId", eventId || 0);
  }, [
    eventId,
    selectedDueCellValues?.value?.value?.operator,
    selectedDueCellValues?.value?.value?.unit,
    selectedDueCellValues?.value?.value?.value,
    setFieldValue,
  ]);
  return (
    <form className="modal-form" onSubmit={handleSubmit}>
      <div className="start">
        <FieldGroup label="Value" error={touched.value && errors.value}>
          <InputField
            name="value"
            value={values.value}
            onChange={handleChange}
          />
        </FieldGroup>

        <FieldGroup label="Unit" error={touched.unit && errors.unit}>
          <SelectField
            value={Units.find((d) => +d.value === values.unit) || null}
            options={Units}
            onChange={(data) => {
              setFieldValue("unit", data.value);
            }}
          />
        </FieldGroup>

        <FieldGroup
          label="Operator"
          error={touched.operator && errors.operator}
        >
          <SelectField
            value={Operators.find((d) => +d.value === values.operator) || null}
            options={Operators}
            onChange={(data) => {
              setFieldValue("operator", data.value);
            }}
          />
        </FieldGroup>

        <FieldGroup label="Event" error={errors.eventId}>
          <SelectField
            isDisabled
            value={eventsList.find((d) => d.value === values.eventId)}
            options={eventsList}
          />
        </FieldGroup>
      </div>

      {/* Actions */}
      <div className="end">
        <Button
          type="button"
          size={Button.Sizes.Small}
          variant={Button.Variant.Danger}
          onClick={onClose}
        >
          Cancel
        </Button>

        <div className="right">
          <Button
            type="button"
            size={Button.Sizes.Small}
            variant={Button.Variant.Info}
            onClick={resetForm}
          >
            Clear
          </Button>
          <Button
            type="submit"
            size={Button.Sizes.Small}
            variant={Button.Variant.Success}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
