import React from "react";
import { differenceInDays, format, isAfter, isToday, isValid } from "date-fns";

import * as CellStyled from "./styled";
import { CellStatusTypes, CellTypes } from "../../../../constant";
import IconButton from "../../../../../../../../common/components/Buttons/IconButton";
import IconTick from "../../../../../../../../common/components/icons/Fill/correct";
import IconWaiting from "../../../../../../../../common/components/icons/Line/waiting";
import { useMilestone } from "../../../../context/MilestoneContext";

const StatusCell = (props) => {
  // console.log(props);

  const { getValue } = props;

  const data = getValue();

  const value = data?.value;

  const passingProps = React.useMemo(() => {
    return { value, cell: props?.cell, row: props?.row, column: props?.column };
  }, [props?.cell, props?.column, props?.row, value]);

  const render = React.useMemo(() => {
    switch (data?.type) {
      case CellTypes.DATE_TYPE: {
        return <DateCell {...passingProps} />;
      }

      case CellTypes.STATUS_TYPE: {
        return <SubmissionCell {...passingProps} />;
      }

      default: {
        return <DefaultCell {...passingProps} />;
      }
    }
  }, [data?.type, passingProps]);

  return <MilestoneWrapper>{render}</MilestoneWrapper>;
};

function DefaultCell(props) {
  const { toggleDeliverableModalHandler } = useMilestone();

  return (
    <CellStyled.StatusCell
      onClick={(e) => {
        toggleDeliverableModalHandler(true, { ...props });
      }}
      $height="inherit"
      {...props}
    />
  );
}

function DateCell(props) {
  const { value } = props;
  const { cellSubMenuHandler } = useMilestone();

  const getDateValue = React.useMemo(() => {
    if (!value) return null;

    if (!isValid(new Date(value.due))) return null;

    const date = new Date(format(new Date(value.due), "yyyy MM dd"));
    const currentDate = new Date(format(new Date(), "yyyy MM dd"));

    // Today
    if (isToday(date))
      return {
        status: "today",
        value: "Today",
      };

    // Over due date
    if (isAfter(currentDate, date)) {
      const dates = differenceInDays(currentDate, date);
      return {
        status: "over",
        value: dates === 1 ? "+ 1 Day" : `+ ${dates} Days`,
      };
    }

    // Default
    return {
      status: "default",
      value: format(date, "dd MMM") || "",
    };
  }, [value]);

  return getDateValue ? (
    <CellStyled.DateStatusCell
      $value={getDateValue.status}
      onClick={() => {
        cellSubMenuHandler({ ...props });
      }}
    >
      <div className="text">{getDateValue.value}</div>
    </CellStyled.DateStatusCell>
  ) : (
    <DefaultCell {...props} />
  );
}

function SubmissionCell(props) {
  const { value } = props;

  const state = value?.state;

  if (!value || !state) return <DefaultCell {...props} />;

  if (state === CellStatusTypes.APPROVED) {
    return <SubmissionInner icon={<IconTick />} state={state} {...props} />;
  }

  if (state === CellStatusTypes.PENDING) {
    return <SubmissionInner icon={<IconWaiting />} state={state} {...props} />;
  }

  if (state === CellStatusTypes.RESUBMIT) {
    return <SubmissionInner text="Resubmit" state={state} {...props} />;
  }

  return null;
}

function SubmissionInner({ state, icon, text, ...rest }) {
  const { toggleDeliverableModalHandler } = useMilestone();

  return (
    <CellStyled.StatusCell
      $value={state}
      onClick={() => {
        toggleDeliverableModalHandler(true, { ...rest });
      }}
    >
      {icon ? <CellIcon icon={icon} /> : null}
      {text ? <div className="text">{text}</div> : null}
    </CellStyled.StatusCell>
  );
}

function MilestoneWrapper({ children, milestones = [] }) {
  return (
    <CellStyled.MilestoneBlock>
      <div className="left">{children}</div>
      {milestones.length ? <div className="right"></div> : null}
    </CellStyled.MilestoneBlock>
  );
}

function CellIcon({ icon }) {
  return (
    <IconButton
      className="cell-status-icon"
      as="div"
      icon={icon}
      btnSize={16}
      btnIconSize={16}
    />
  );
}

export default StatusCell;
