export const response = {
  projectDetails: {
    projectId: 2,
    tenantId: 1,
    projectName: "GGG",
    projectLogo: "",
    projectDescription: null,
    projectContactPersonName: null,
    projectContactPersonJobTitle: null,
    projectContactPersonEmail: null,
    projectContactPersonTelephoneNo: null,
    projectNoOfEpisodes: 2,
    projectTrailerUrl: null,
    projectDeckUrl: null,
    estimateProductionDate: null,
    projectPrivacyId: 1,
    projectTypeId: 1,
    startDate: "2023-03-21T00:00:00",
    endDate: "2024-09-20T00:00:00",
    clientId: 1,
    duration: "44",
    broadcastTitle: "",
    txDate: "2024-11-30T00:00:00",
    languageId: 38,
    filmingFormatId: 1,
    deliveryFormatId: 1,
  },
  tableHeaders: [
    {
      columnId: 1,
      label: "Deliverables",
      type: "deliverables",
    },
    {
      columnId: 2,
      label: "Series",
      type: "series",
    },
    {
      columnId: 3,
      label: "",
      type: "episode",
    },
    {
      columnId: 4,
      label: "test episode",
      type: "episode",
    },
    {
      columnId: 5,
      label: "Due",
      type: "due",
    },
  ],
  sections: [
    {
      id: 1,
      uuid: 1,
      projectId: 2,
      eventId: 1,
      parentId: 0,
      1: {
        cellId: 0,
        delivarableId: 0,
        columnId: 0,
        label: "Fine Cut - I",
        type: 1,
        submission: null,
        due: null,
        condition: null,
      },
      2: {
        cellId: 0,
        delivarableId: 0,
        columnId: 0,
        label: null,
        type: 1, // 1= submission, 2 = due
        value: {
          // submission or due object
          submissionId: 0,
          subject: null,
          note: null,
          changeRequest: null,
          state: null,
          cellId: 0,
          changeLogs: [],
          changeRequests: [],
          resources: [],
        },
      },
      3: {
        cellId: 0,
        delivarableId: 0,
        columnId: 0,
        label: null,
        type: 0,
        value: {
          dueId: 0,
          due: new Date(),
          cellId: 0,
        },
      },
      4: {
        cellId: 0,
        delivarableId: 0,
        columnId: 0,
        label: null,
        type: 0,
        value: {
          dueId: 0,
          due: new Date(),
          cellId: 0,
        },
      },
      5: {
        cellId: 0,
        delivarableId: 0,
        columnId: 0,
        label: "Prior Commissioning",
        type: 0, // Not important
        value: {
          conditionId: 0,
          value: 0,
          unit: 0,
          operator: 0,
          eventId: 0,
          cellId: 0,
        },
      },
      subSections: [
        {
          id: 2,
          uuid: 2,
          projectId: 2,
          eventId: 1,
          parentId: 1,
          1: {
            cellId: 0,
            delivarableId: 0,
            columnId: 0,
            label: "Fine Cut - I",
            type: 1,
            submission: null,
            due: null,
            condition: null,
          },
          2: {
            cellId: 0,
            delivarableId: 0,
            columnId: 0,
            label: null,
            type: 1, // 1= submission, 2 = due
            value: {
              // submission or due object
              submissionId: 0,
              subject: null,
              note: null,
              changeRequest: null,
              state: null,
              cellId: 0,
              changeLogs: [],
              changeRequests: [],
              resources: [],
            },
          },
          3: {
            cellId: 0,
            delivarableId: 0,
            columnId: 0,
            label: null,
            type: 0,
            value: {
              dueId: 0,
              due: "0001-01-01T00:00:00",
              cellId: 0,
            },
          },
          4: {
            cellId: 0,
            delivarableId: 0,
            columnId: 0,
            label: null,
            type: 0,
            value: {
              dueId: 0,
              due: "0001-01-01T00:00:00",
              cellId: 0,
            },
          },
          5: {
            cellId: 0,
            delivarableId: 0,
            columnId: 0,
            label: "Prior Commissioning",
            type: 0, // Not important
            value: {
              conditionId: 0,
              value: 0,
              unit: 0,
              operator: 0,
              eventId: 0,
              cellId: 0,
            },
          },
          subSections: [],
        },
      ],
    },
  ],
};

export const data = [
  {
    uuid: 230,
    1: "test 1",
    2: {
      type: 0,
      value: {
        dueId: 0,
        due: new Date(2024, 9, 1),
        cellId: 0,
      },
    },
    3: {
      type: 1,
      value: {
        state: 1,
      },
    },
    4: "Prior Commissioning",
    subRows: [],
  },
];
