import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { produce } from "immer";

import UIButton from "../../Buttons/UIButton";
import InputField from "../InputField";
import { IconBlock } from "../../../../styled/Common/Icons";
import { Add1 } from "../../icons/Line";
import { ListItem } from "./components/ListItem";
import * as Styled from "./styled";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is a required field"),
  url: Yup.string()
    .required("URL is a required field")
    .url("Link must be a valid URL"),
});

const initialValues = {
  name: "",
  url: "",
};

const InsertUrlList = ({ outerClassName, value, onChange, isPreviewMode }) => {
  const nameFieldRef = React.useRef();
  const [list, setList] = React.useState([]);

  const onSubmitHandler = React.useCallback(
    (values, { resetForm, setFieldError }) => {
      const nameExist = list.find((d) => d.name === values.name);
      const urlExist = list.find((d) => d.url === values.url);

      if (nameExist) {
        setFieldError("name", "Name already exists");
        return;
      }

      if (urlExist) {
        setFieldError("url", "URL already exists");
        return;
      }

      setList(
        produce((draft) => {
          draft.push({
            ...values,
            id: uuidv4(),
          });
        })
      );

      // clear form
      resetForm();
      // Focus to name field
      nameFieldRef?.current?.focus();
    },
    [list]
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: onSubmitHandler,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { values, errors, touched, handleChange, setFieldValue } = formik;

  const handleDelete = React.useCallback((id) => {
    setList(
      produce((draft) => {
        return draft.filter((d) => d.id !== id);
      })
    );
  }, []);

  const handleEdit = React.useCallback(
    (id) => {
      const item = list.find((d) => d.id === id);
      setFieldValue("name", item.name);
      setFieldValue("url", item.url);
    },
    [list, setFieldValue]
  );

  React.useEffect(() => {
    onChange && onChange(list);
    // if (!!list.length) {
    // }
  }, [list, onChange]);

  React.useEffect(() => {
    if (value && value?.length) {
      setList(value);
    }
  }, [value]);

  return (
    <Styled.UrlList className={outerClassName}>
      {!isPreviewMode ? (
        <div className="input__wrapper">
          <div className="form">
            <div className="form__inputs">
              <InputField
                ref={nameFieldRef}
                name="name"
                type="text"
                placeholder="Name"
                inputOuterClass="text__input"
                value={values.name}
                onChange={handleChange}
              />

              <InputField
                name="url"
                type="text"
                placeholder="URL"
                inputOuterClass="text__input"
                value={values.url}
                onChange={handleChange}
              />

              <Styled.AddButton
                type="button"
                onClick={formik.handleSubmit}
                variant={UIButton.Variant.Primary}
              >
                <IconBlock className="icon">
                  <Add1 />
                </IconBlock>
              </Styled.AddButton>
            </div>
          </div>

          {errors.url || errors.name ? (
            <div className="errors">
              <div className="error">{touched.name && errors.name}</div>
              <div className="error">{touched.url && errors.url}</div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="current__list__wrapper">
        <Styled.List>
          {list.map((item) => (
            <ListItem
              key={item.id}
              id={item.id}
              name={item.name}
              url={item.url}
              onDelete={handleDelete}
              onEdit={handleEdit}
              isPreviewMode={isPreviewMode}
            />
          ))}
        </Styled.List>
      </div>
    </Styled.UrlList>
  );
};

export default InsertUrlList;
