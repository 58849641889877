import React from "react";
import { OnboardingActionType } from "../../../../../constant/ActionTypes";
import { ONBOARDING_STATUS } from "../../../../../constant/Status";
import OnboardingItems from "../OnboardingList";

const HRListItem = (props) => {
  const { data: item, onActionClick, viewType } = props;

  const onActionChangeHandler = (action) => {
    onActionClick && onActionClick(action);
  };

  const onRegenerateHandler = React.useCallback(() => {
    const _action = {
      type: OnboardingActionType.GENERATE_CONTRACT,
      payload: {
        member: { ...item, statusId: 6, isRegenerate: true },
      },
    };

    onActionClick && onActionClick(_action);
  }, [item, onActionClick]);

  const { statusId } = item;

  // Status of the Button action
  const actionButtonState = React.useMemo(() => {
    let _actionState = {
      actionText: "none",
      isDisabled: true,
    };

    // 6
    if (statusId === ONBOARDING_STATUS.ContractRequested) {
      _actionState = {
        actionText: "Generate Contract",
        isDisabled: false,
      };
    }
    // 7 View and can document edit also view comments
    if (statusId === ONBOARDING_STATUS.ContractChangeRequested) {
      _actionState = {
        actionText: "View or Generate Contract",
        isDisabled: false,
      };
    }

    // 8 Only view
    if (
      statusId === ONBOARDING_STATUS.ContractGenerated ||
      statusId === ONBOARDING_STATUS.ContractAcceptingByHR ||
      statusId === ONBOARDING_STATUS.ContractAcceptedByHR ||
      statusId === ONBOARDING_STATUS.Onboarded
    ) {
      _actionState = {
        actionText: "View Contract",
        isDisabled: false,
      };
    }

    // 10, 11, 13
    if (
      statusId === ONBOARDING_STATUS.ContractSigned ||
      statusId === ONBOARDING_STATUS.ContractExecuted ||
      statusId === ONBOARDING_STATUS.ContractAccepted
    ) {
      _actionState = {
        actionText: "Onboard",
        isDisabled: false,
      };
    }

    return _actionState;
  }, [statusId]);

  // [15,12,14,20,11,13]
  const notAllowStatusToRegenerate = [
    ONBOARDING_STATUS.Onboarded,
    ONBOARDING_STATUS.ContractRejected,
    ONBOARDING_STATUS.ContractRejectedIsAck,
    ONBOARDING_STATUS.MemberContractDeclined,
    ONBOARDING_STATUS.ContractAccepted,
    ONBOARDING_STATUS.ContractExecuted,
  ];

  const canContractReGenerated =
    ONBOARDING_STATUS.ContractGenerated <= statusId &&
    !notAllowStatusToRegenerate.includes(statusId);

  const menuItems = React.useMemo(() => {
    return [
      {
        eventKey: "re-generate",
        label: " Re-Generate Contract",
        disabled: !canContractReGenerated,
        onClick: canContractReGenerated ? onRegenerateHandler : null,
      },
    ];
  }, [canContractReGenerated, onRegenerateHandler]);

  return (
    <OnboardingItems.Item
      data={item}
      viewType={viewType}
      hasActionPermission={true}
      onActionClick={onActionChangeHandler}
      menuItems={menuItems}
      pageType="hr"
    />
  );
};

export default HRListItem;
