import React from "react";
import { Modal } from "./styled";
import Form from "./Form";
import { useMilestone } from "../../../context/MilestoneContext";

const res = {
  submissionId: 1,
  subject: "Submission",
  note: "Noted - II",
  state: 1,
  cellId: 29,
  changeLogs: [
    { changeLogId: 1, submissionId: 123, userId: "user123", from: 1, to: 2 },
  ],
  changeRequests: [
    {
      changeRequestId: 1,
      text: "Request to update submission details.",
      userId: "user123",
      state: 1,
      submissionId: 456,
    },
  ],
  links: [
    {
      resourceId: 0,
      resourceType: 1,
      url: "string",
      label: "string",
      extension: "string",
      md5sum: "string",
      sectionValue: null,
      isSorted: false,
    },
  ],
  attachments: [
    {
      resourceId: 0,
      resourceType: 1,
      url: "string",
      label: "string",
      extension: "string",
      md5sum: "string",
      sectionValue: null,
      isSorted: false,
    },
  ],
};

function DeliverablePopup() {
  const {
    isVisibleDeliverableModal,
    deliverableModalState,
    toggleDeliverableModalHandler,
  } = useMilestone();

  console.log("Deliverable Modal State", deliverableModalState);

  return (
    <Modal
      visibility={isVisibleDeliverableModal}
      onClose={() => toggleDeliverableModalHandler(false)}
      bodyComponent={(props) => <Form {...props} />}
    />
  );
}

export default DeliverablePopup;
