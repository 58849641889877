import React from "react";
import { Navigate } from "react-router";

import MainLayout from "../components/pages/layout/mainLayout";
import SetupContainer from "../components/pages/settings/setup-container";
import Projects from "../components/pages/project";
import UIPage from "../components/pages/ui";
import PageNotFound from "../pages/PageNotFound";
import ResetPassword from "../components/pages/login/reset-password";
import CreateNewPassword from "../components/pages/login/create-new-password";
import ChangePassword from "../components/pages/login/change-password";
// import CreateNewAccount from "../components/pages/account/create-account";
// import CreateNewAccountUserDetails from "../components/pages/account/create-account-user-details";
import Crew from "../components/pages/crew";
import Templates from "../components/pages/template";
import ProjectMemberConfirmation from "../components/pages/onBoarding/project-member-confirmation";
import BreadcrumbLink from "../components/ui-components/Breadcrumb/BreadcrumbLink";
import Loader from "../components/ui-components/Loader";
// Child routes
import { projectRoutes } from "./route/projects/projects-route";
import { settingRoutes } from "./route/setting-route";
import { templateRoutes } from "./route/templates-route";
import { crewRoutes } from "./route/crew-route";
import { ROUTE_OPTIONS } from "../constant/route-options";
import { PublicRoutePath } from "./paths/public-path";
import { permissionLoader } from "./loaders";
import PageErrorBoundary from "../pages/PageErrorBoundary";
import ResearchBoard from "../pages/research-board";
import { researchBoardRoutes } from "./route/research-board-routes";
// New UI Pages
import LoginUI from "../pages/Auth/screens/Login";
import ResetPasswordUI from "../pages/Auth/screens/ResetPassword";
import CreateNewPasswordUI from "../pages/Auth/screens/CreateNewPassword";
import CreateNewAccount from "../pages/Auth/screens/CreateAccount";
import EmailVerificationUI from "../pages/Auth/screens/EmailVerfication";
import UserAccountDetails from "../pages/Auth/screens/CreateAccount/UserAccountDetails";
import Version from "../pages/Version";

import { SettingRoutes } from "../pages/Setting/routes";
import { SchedulerRoutes } from "../pages/Scheduler/routes";
import { DashboardRoutes } from "./../pages/Dashboard/routes";
import { BudgetRoutes } from "./../pages/Budget/routes/index";
import { TemplateRoutes } from "./../pages/Template/routes";
import { OnboardingRoutes } from "./../pages/Onboarding/routes";
import { MilestoneTrackerRoutes } from "../pages/MilestoneTracker/routes";

const permissionCache = {};
let callCount = 0;
let lastCallTime = Date.now();

/**
 * @returns {boolean} - True if rate limit is exceeded, false otherwise
 */
const isRateLimitExceeded = () => {
  const RATE_LIMIT_THRESHOLD = 5; // Max number of calls allowed for cached data
  const RATE_LIMIT_SECONDS = 6; // max seconds to check cached data
  const currentTime = Date.now();
  const timeSinceLastCall = (currentTime - lastCallTime) / 1000; // Convert ms to seconds

  // Reset
  if (timeSinceLastCall > RATE_LIMIT_SECONDS) {
    callCount = 0;
  }

  lastCallTime = currentTime;
  callCount += 1;

  // Return true if call count exceeds
  return callCount > RATE_LIMIT_THRESHOLD;
};

const executePermissionLoader = async (permissionType, routeId) => {
  try {
    return await permissionLoader(permissionType, routeId);
  } catch (error) {
    console.error(
      `Error in permission loader for ${permissionType} and route ${routeId}: `,
      error
    );
    throw error;
  }
};

const allRoutes = [
  // public routes
  {
    id: PublicRoutePath.LoginUI.id,
    path: PublicRoutePath.LoginUI.path,
    element: <LoginUI />,
  },

  {
    id: PublicRoutePath.EmailVerificationUI.id,
    path: PublicRoutePath.EmailVerificationUI.path,
    element: <EmailVerificationUI />,
  },

  {
    id: PublicRoutePath.ResetPasswordUI.id,
    path: PublicRoutePath.ResetPasswordUI.path,
    element: <ResetPasswordUI />,
  }, // { //   id: PublicRoutePath.CreateAccountUI.id, //   path: PublicRoutePath.CreateAccountUI.path, //   element: <CreateAccountUI />, // },

  {
    id: PublicRoutePath.NewPasswordUI.id,
    path: PublicRoutePath.NewPasswordUI.path,
    element: <CreateNewPasswordUI />,
  }, // { //   id: PublicRoutePath.CreateAccountDetailsUI.id, //   path: PublicRoutePath.CreateAccountDetailsUI.path, //   element: <UserAccountDetails />, // },

  {
    id: PublicRoutePath.Login.id,
    path: PublicRoutePath.Login.path,
    element: <LoginUI />,
  },

  {
    id: PublicRoutePath.CreateAccount.id,
    path: PublicRoutePath.CreateAccount.path,
    element: <CreateNewAccount />, // element: <CreateNewAccount />,
  },

  {
    id: PublicRoutePath.Verification.id,
    path: PublicRoutePath.Verification.path,
    element: <EmailVerificationUI />,
  },

  {
    id: PublicRoutePath.CreateAccountDetails.id,
    path: PublicRoutePath.CreateAccountDetails.path,
    element: <UserAccountDetails />, // element: <CreateNewAccountUserDetails />,
  },

  {
    id: PublicRoutePath.NewPassword.id,
    path: PublicRoutePath.NewPassword.path,
    element: <CreateNewPassword />,
  },

  {
    id: PublicRoutePath.ResetPassword.id,
    path: PublicRoutePath.ResetPassword.path,
    element: <ResetPassword />,
  },

  {
    id: PublicRoutePath.ChangePassword.id,
    path: PublicRoutePath.ChangePassword.path,
    element: <ChangePassword />,
  }, // Private Routes // Inner pages with main layout

  {
    id: "private-routes",

    element: (
      <React.Suspense fallback={<Loader />}>
        <MainLayout />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <BreadcrumbLink
          iconClassName="icon-lp-home"
          label="Home"
          to={ROUTE_OPTIONS.dashboard.path}
        />
      ),
    },

    children: [
      {
        errorElement: <PageErrorBoundary />,
        children: [
          // New Dashboard
          { ...DashboardRoutes },

          /**
           * New Settings routes
           */
          {
            id: ROUTE_OPTIONS.setting.id,
            path: ROUTE_OPTIONS.setting.path,
            element: <SetupContainer />,
            handle: {
              crumb: () => (
                <BreadcrumbLink
                  label={ROUTE_OPTIONS.setting.label}
                  to={ROUTE_OPTIONS.setting.path}
                />
              ),
            },

            children: settingRoutes,
            loader: async () => {
              return await permissionLoader(
                "tenant-settings",
                ROUTE_OPTIONS.setting.id
              );
            },
          },

          // Project routes
          { ...SettingRoutes },

          /**
           * Scheduler routes
           */
          { ...SchedulerRoutes },

          /**
           * Budget routes
           */
          { ...BudgetRoutes },

          /**
           * Milestone Tracker routes
           */
          { ...MilestoneTrackerRoutes },

          // New Template routes
          {
            ...TemplateRoutes,
          },

          // Onboarding Routes
          { ...OnboardingRoutes },

          {
            id: ROUTE_OPTIONS.projects.id,
            path: ROUTE_OPTIONS.projects.path,
            element: <Projects />,
            children: projectRoutes,
            handle: {
              crumb: () => (
                <BreadcrumbLink
                  label={ROUTE_OPTIONS.projects.label}
                  to={ROUTE_OPTIONS.projects.path}
                />
              ),
            },
            loader: async () => {
              return await permissionLoader(
                ROUTE_OPTIONS.projects.id,
                ROUTE_OPTIONS.projects.id
              );
            },
          }, // Templates routes

          {
            id: ROUTE_OPTIONS.templates.id,
            path: ROUTE_OPTIONS.templates.path,
            element: <Templates />,
            children: templateRoutes,
            handle: {
              crumb: () => (
                <BreadcrumbLink
                  label={ROUTE_OPTIONS.templates.label}
                  to={ROUTE_OPTIONS.templates.path}
                />
              ),
            },
            loader: async () => {
              //
              return true;
              // return await permissionLoader(
              //   ROUTE_OPTIONS.templates.id,
              //   ROUTE_OPTIONS.templates.id
              // );
            },
          }, // Crew routes

          {
            id: ROUTE_OPTIONS.crew.id,
            path: ROUTE_OPTIONS.crew.path,
            element: <Crew />,
            children: crewRoutes,
            handle: {
              crumb: () => (
                <BreadcrumbLink
                  label={ROUTE_OPTIONS.crew.label}
                  to={ROUTE_OPTIONS.crew.path}
                />
              ),
            },

            loader: async () => {
              return true;
              // return await permissionLoader(ROUTE_OPTIONS.crew.id, );
            },
          }, // confirm-project-member

          {
            id: "confirm-project-member",
            path: "confirm-project-member/:id",
            element: <ProjectMemberConfirmation />,
            handle: {
              crumb: () => <BreadcrumbLink label="Confirm Project Member" />,
            },
          }, // Research Board routes

          {
            id: ROUTE_OPTIONS.research_board.id,
            path: ROUTE_OPTIONS.research_board.path,
            element: <ResearchBoard />,
            children: researchBoardRoutes,
            handle: {
              crumb: () => (
                <BreadcrumbLink
                  label={ROUTE_OPTIONS.research_board.label}
                  to={ROUTE_OPTIONS.research_board.path}
                />
              ),
            },

            loader: async () => {
              const cacheKey = `${ROUTE_OPTIONS.research_board.id}-research`;

              if (permissionCache[cacheKey] && !isRateLimitExceeded()) {
                //console.log('Returning cached permission data');
                return permissionCache[cacheKey];
              }

              if (isRateLimitExceeded()) {
                console.log(
                  "Rate limit exceeded, calling PermissionAPI despite cache data..."
                );
                return await executePermissionLoader(
                  "research",
                  ROUTE_OPTIONS.research_board.id
                );
              }

              // (initial fetch) Fetch permission data if not cached
              console.log(
                "Calling Permission api for: ",
                ROUTE_OPTIONS.research_board.id
              );
              const permission = await executePermissionLoader(
                "research",
                ROUTE_OPTIONS.research_board.id
              );

              permissionCache[cacheKey] = permission;
              return permission;
            },
          }, // UI route

          {
            path: "/ui",
            element: <UIPage />,
          },

          {
            path: "/version",
            element: <Version />,
          },

          {
            path: "*",
            element: (
              <Navigate
                to={`/${ROUTE_OPTIONS.dashboard.path}`}
                replace={true}
              />
            ),
          },
        ],
      },
    ],
  }, // Not found routes
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default allRoutes;
