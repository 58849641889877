import React from "react";
import { CalendarPopoverWrapper, PopoverMenu } from "./styled";
import { useMilestone } from "../../../../../context/MilestoneContext";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";
import IconEdit from "../../../../../../../../../common/components/icons/Line/Edit/01";
import IconCalendar from "../../../../../../../../../common/components/icons/Fill/Calendar/01";
import Calendar from "../../../../../../../../../common/components/Calendar";

const CellSubMenuPopover = () => {
  const {
    cellSubMenuState,
    cellSubMenuHandler,
    toggleDeliverableModalHandler,
  } = useMilestone();

  const [calendarElement, setCalendarElement] = React.useState(null);

  if (!cellSubMenuState?.element) return null;

  return (
    <PopoverMenu
      anchorEl={cellSubMenuState?.element}
      onOutsideClick={() => {
        cellSubMenuHandler(null);
      }}
    >
      <div className="menu__wrapper">
        <MenuButton
          text="Edit"
          icon={<IconEdit />}
          onClick={() => {
            toggleDeliverableModalHandler(true, { ...cellSubMenuState });
          }}
        />
        <div className="menu-divider"></div>
        <MenuButton
          text="Change Date"
          icon={<IconCalendar />}
          onClick={(e) => {
            setCalendarElement((prev) => (prev ? null : e.currentTarget));
          }}
        />

        <CalendarPopover
          element={calendarElement}
          onClose={() => {
            setCalendarElement(null);
          }}
        />
      </div>
    </PopoverMenu>
  );
};

function MenuButton({ icon, text, ...rest }) {
  return (
    <div className="menu-item">
      <button type="button" {...rest}>
        <div className="icon">
          <IconButton as="div" icon={icon} btnIconSize={16} btnSize={16} />
        </div>
        <div className="text">{text}</div>
      </button>
    </div>
  );
}

function CalendarPopover({ element, onClose }) {
  const [selected, setSelected] = React.useState(undefined);

  if (!element) {
    return null;
  }

  return (
    <CalendarPopoverWrapper
      anchorEl={element}
      appendTo={document.body}
      onOutsideClick={() => {
        onClose();
      }}
      placement="right-start"
      isOverlayTransparent
    >
      <div className="calendar-wrapper">
        <Calendar
          mode="single"
          selected={selected}
          onSelect={(val) => {
            setSelected(val);
            onClose();
          }}
          defaultMonth={selected}
        />
      </div>
    </CalendarPopoverWrapper>
  );
}

export default CellSubMenuPopover;
